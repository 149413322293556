<template>
  <template v-if="view === 0">
    <div class="flex flex-col gap-4">
      <section id="header" class="flex justify-center">
        <span class="font-bold text-2xl">
          Buscar Accionado
        </span>
      </section>

      <section id="body" class="flex flex-col items-center gap-4">
        <div class="flex flex-col w-1/4">
          <span>Tipo de Documento:</span>
          <Dropdown v-model="accionado.docTipoId" :options="listDocTipos" optionLabel="nombre" optionValue="code" :filter="true"/>
        </div>
        <div class="flex flex-col  w-1/4">
          <span>Numero de Documento:</span>
          <InputText type="text" v-model="accionado.numeroIdentificacion"/>
        </div>
      </section>

      <section id="footer" class="flex justify-center gap-4">
        <Button label="Atras"
                icon="pi pi-arrow-left"
                class="p-button-danger"
                @click="$router.push({name: 'pharmasan.administrativa.juridica.procesos-judiciales.listado'})"
        />
        <Button label="Buscar"
                icon="pi pi-search"
                @click="search()"
        />
      </section>
    </div>

  </template>
  <template v-else-if="view === 1">
    <div class="flex flex-col gap-4">
      <section id="headerInfo" class="flex justify-center">
        <span class="font-bold text-2xl">
          Información Accionado
        </span>
      </section>

      <section id="bodyInfo" class="flex flex-col items-center gap-2">
        <div class="flex flex-col w-2/4">
          <span class="text-xs">Nombre Completo:</span>
          <InputText type="text" v-model="model.fullname"/>
          <MessageError :text="errors.fullname" />
        </div>
        <div class="flex w-2/4 gap-4">
          <div class="flex flex-col w-1/4">
            <span class="text-xs">Tipo de Documento:</span>
            <Dropdown v-model="model.docTipoId" :options="listDocTipos" optionLabel="nombre" optionValue="code" :filter="true"/>
            <MessageError :text="errors.docTipoId" />
          </div>
          <div class="flex flex-col w-2/4">
            <span class="text-xs">Numero de Documento:</span>
            <InputText type="text" v-model="model.numeroIdentificacion" :disabled="!isNew"/>
            <MessageError :text="errors.numeroIdentificacion" />
          </div>
          <div class="flex flex-col w-1/4">
            <span class="text-xs">Tipo de Persona:</span>
            <Dropdown v-model="model.personaJuridica" :options="listPerTipos" optionLabel="nombre" optionValue="value"/>
            <MessageError :text="errors.docTipoId" />
          </div>
        </div>
        <div class="flex flex-col  w-2/4">
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Numero de Teléfono:</span>
          <InputText type="text" v-model="model.telefono"/>
          <MessageError :text="errors.telefono" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Correo Electronico:</span>
          <InputText type="text" v-model="model.correo"/>
          <MessageError :text="errors.correo" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Departamento:</span>
          <Dropdown v-model="model.departamentoId" :options="departamentos" optionLabel="nomDpto" optionValue="codDpto" :filter="true" @change="onChangeDepartamentos($event)"/>
          <MessageError :text="errors.departamentoId" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Municipio:</span>
          <Dropdown v-model="model.municipioId" :options="municipios" optionLabel="nomMpio" optionValue="codMpio" :filter="true"/>
          <MessageError :text="errors.municipioId" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Dirección de Residencia:</span>
          <InputText type="text" v-model="model.direccion"/>
          <MessageError :text="errors.direccion" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Nombre del Representante Legal (opcional):</span>
          <InputText type="text" v-model="model.representanteLegal"/>
          <MessageError :text="errors.representanteLegal" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Numero de Contacto (opcional):</span>
          <InputText type="text" v-model="model.representanteLegalContacto"/>
          <MessageError :text="errors.representanteLegalContacto" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Nombre Apoderado:</span>
          <InputText type="text" v-model="model.apoderadoNombre"/>
          <MessageError :text="errors.apoderadoNombre" />
        </div>
        <div class="flex w-2/4 gap-4">
          <div class="flex flex-col  w-1/4">
            <span class="text-xs">Tipo Doc.:</span>
            <Dropdown v-model="model.apoderadoTipoDoc" :options="listDocTipos" optionLabel="nombre" optionValue="code" :filter="true"/>
            <MessageError :text="errors.apoderadoTipoDoc" />
          </div>
          <div class="flex flex-col  w-3/4">
            <span class="text-xs">Documento:</span>
            <InputText type="text" v-model="model.apoderadoDocumento"/>
            <MessageError :text="errors.apoderadoDocumento" />
          </div>
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Tarjeta Profesional:</span>
          <InputText type="text" v-model="model.apoderadoTarjeta"/>
          <MessageError :text="errors.apoderadoTarjeta" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Correo:</span>
          <InputText type="text" v-model="model.apoderadoCorreo"/>
          <MessageError :text="errors.apoderadoCorreo" />
        </div>
        <div class="flex flex-col  w-2/4">
          <span class="text-xs">Telefono:</span>
          <InputText type="text" v-model="model.apoderadoTelefono"/>
          <MessageError :text="errors.apoderadoTelefono" />
        </div>
      </section>

      <section id="footerInfo" class="flex justify-center gap-4">
        <div class="flex justify-between w-2/4">
          <Button label="Atras"
                  icon="pi pi-angle-left"
                  class="p-button-danger"
                  @click="view = 0"
          />
          <Button label="Siguiente"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  @click="nextPage()"
          />
        </div>
      </section>
    </div>
  </template>
</template>

<script>
import DocTiposStore from '../../../../../store/docTipos.store'
import PersonaNaturalStore from '../../../../../store/personaNatural.store'
import ProcesosJudicialesStore from '../../../../../store/procesosJudiciales.store'
import LocalizacionStore from '../../../../../store/localizacion.store'
import { ref, onActivated, computed } from 'vue'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { useRouter } from 'vue-router'

export default {
  name: 'buscar-accionado',
  setup () {
    const router = useRouter()
    const listDocTipos = computed(() => DocTiposStore.getters.getListDocTipos)
    const accionadoSearch = computed(() => PersonaNaturalStore.getters.getSearchPersona)
    const departamentos = computed(() => LocalizacionStore.getters._departamentos)
    const municipios = computed(() => LocalizacionStore.getters._municipios)

    const listPerTipos = ref([
      { nombre: 'Persona Natural', value: false },
      { nombre: 'Persona Juridica', value: true }
    ])
    const accionado = ref({
      docTipoId: '',
      numeroIdentificacion: ''
    })
    const view = ref(0) // 0: buscar accionado, 1: informacion accionado
    const schema = yup.object({
      fullname: yup.string().required('El campo es requerido'),
      docTipoId: yup.string().required('El campo es requerido').label(''),
      numeroIdentificacion: yup.string().required('El campo es requerido').label(''),
      telefono: yup.string().required('El campo es requerido'),
      correo: yup.string().email().required('El campo es requerido'),
      municipioId: yup.string().required('El campo es requerido'),
      departamentoId: yup.string().required('El campo es requerido'),
      direccion: yup.string().required('El campo es requerido'),
      representanteLegal: yup.string().nullable(),
      representanteLegalContacto: yup.string().nullable(),
      personaJuridica: yup.boolean().required('El campo es requerido'),
      apoderadoNombre: yup.string().nullable(true),
      apoderadoTipoDoc: yup.string().nullable(true),
      apoderadoDocumento: yup.string().nullable(true),
      apoderadoTarjeta: yup.string().nullable(true),
      apoderadoCorreo: yup.string().nullable(true),
      apoderadoTelefono: yup.string().nullable(true)
    })
    const isNew = ref(false)
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })

    useField('fullname')
    useField('docTipoId')
    useField('numeroIdentificacion')
    useField('telefono')
    useField('correo')
    useField('municipioId')
    useField('departamentoId')
    useField('direccion')
    useField('representanteLegal')
    useField('representanteLegalContacto')
    useField('personaJuridica', null, { initialValue: false })
    useField('apoderadoNombre')
    useField('apoderadoTipoDoc')
    useField('apoderadoDocumento')
    useField('apoderadoTarjeta')
    useField('apoderadoCorreo')
    useField('apoderadoTelefono')

    const searchMunicipio = (codDpto) => {
      LocalizacionStore.dispatch('getMunicipios', codDpto)
    }

    const search = () => {
      if (accionado.value.docTipoId === '' || accionado.value.numeroIdentificacion === '') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Debe ingresar el tipo de documento y el numero de documento'
        })
        return
      }
      PersonaNaturalStore.dispatch('searchPersona', accionado.value).then((res) => {
        searchMunicipio(accionadoSearch.value.departamentoId)
        isNew.value = !res
        model.id = res.id
        view.value = 1
        model.fullname = accionadoSearch.value.fullname
        model.docTipoId = accionadoSearch.value.docTipoId
        model.numeroIdentificacion = accionadoSearch.value.numeroIdentificacion
        model.telefono = accionadoSearch.value.telefono
        model.correo = accionadoSearch.value.correo
        model.municipioId = accionadoSearch.value.municipioId
        model.departamentoId = accionadoSearch.value.departamentoId
        model.direccion = accionadoSearch.value.direccion
        model.representanteLegal = accionadoSearch.value.representanteLegal
        model.representanteLegalContacto = accionadoSearch.value.representanteLegalContacto
        model.personaJuridica = accionadoSearch.value.personaJuridica
        model.apoderadoNombre = accionadoSearch.value.apoderadoNombre
        model.apoderadoTipoDoc = accionadoSearch.value.apoderadoTipoDoc
        model.apoderadoDocumento = accionadoSearch.value.apoderadoDocumento
        model.apoderadoTarjeta = accionadoSearch.value.apoderadoTarjeta
        model.apoderadoCorreo = accionadoSearch.value.apoderadoCorreo
        model.apoderadoTelefono = accionadoSearch.value.apoderadoTelefono
        accionado.value = {
          docTipoId: '',
          numeroIdentificacion: ''
        }
      })
    }

    const onChangeDepartamentos = (e) => {
      searchMunicipio(e.value)
      model.municipioId = ''
    }

    const nextPage = handleSubmit((values) => {
      const { id } = values
      delete values.id
      ProcesosJudicialesStore.dispatch('setNewProcesoJudicialAccionado', {
        accionadoId: id,
        accionado: values
      })
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.buscar-accionante' })
    })

    onActivated(() => {
      DocTiposStore.dispatch('search')
      LocalizacionStore.dispatch('getDeparatamentos')
    })

    return {
      accionado,
      listDocTipos,
      view,
      errors,
      model,
      isNew,
      departamentos,
      municipios,
      listPerTipos,
      search,
      nextPage,
      searchMunicipio,
      onChangeDepartamentos
    }
  }
}
</script>

<style scoped>

</style>
